import React from "react";
import Constants from "src/Constants";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from "react-router-dom";

let statusMap = {
    200: {color: "#03b903", icon: "fa-check-circle"},
    500: {color: "crimson", icon: "fa-times-circle"}
}


const InfoPage = () => {

    const { id } = useParams()

    const [content, setContent] = React.useState({message: '', text: '', status: ''});

    React.useEffect(() => {
        let url = Constants.acknoledgement + id;
        (async () => {
            let response = await fetch(url,{
                method: 'GET'
            })
            let data = await response.json();
            setContent(data);
        })()
    }, [id])

    return (
        <div className="container" style={{maxWidth: '900px'}}>
            <div className="row" style={{height: '100vh'}}>
                <div className="col-12 content-paging text-center my-auto">
                    <h1 className="md-my-5 my-2">{content.message}</h1>
                    <p className="lead">{content.text}</p>
                    <h1 className={`fa-solid fa-4x ${statusMap[content.status]?.icon}`} style={{color: statusMap[content.status]?.color}}> </h1>
                    <br/>
                    <a href="/" className="btn btn-primary d-inline-block">Go Back to Homepage</a>
                </div>
            </div>
        </div>
    );
}

export default InfoPage;