// Live = 'https://api.atxfloods.com/'
// Local = http://192.168.0.110/

export async function downloadCSV(url, name, selector) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: userToken()
        }
    })
    let data = await response.json();
    console.log(data, selector)
    if (data[selector]?.length >= 1) {
        let header = `${Object.keys(data[selector][0]).map((item) => `"${item}"`).join(',')}\n`
        let body = ``
        data[selector].forEach((row) => {
            body += `${Object.values(row).map((item) => `"${typeof item === "object" ? "" : item}"`).join(',')}\n`
        })

        let resObjUrl = URL.createObjectURL(new Blob([header + body], { type: 'text/csv' }))

        let a = document.createElement('a')
        a.href = resObjUrl;
        a.download = name;
        a.click()

    }

}
const prefix = 'https://api.atxfloods.com/';
const Constants = {
    base: prefix,
    login: prefix + 'admin/auth/login',
    me: prefix + 'admin/me',
    changePassword: prefix + 'admin/auth/change-password',
    counts: prefix + 'admin/counts',
    crossings: prefix + 'admin/crossings/all',
    crossingsImport: prefix + 'admin/crossings/import',
    crossingsExport: prefix + 'admin/crossings/export',
    deleteCrossing: prefix + 'admin/crossings/delete/',
    createCrossing: prefix + 'admin/crossings/create',
    updateCrossing: prefix + 'admin/crossings/update/',
    updateCrossingStatus: prefix + 'admin/crossings/update-status/',
    closures: prefix + 'admin/closures',
    cameras: prefix + 'admin/cameras/all',
    singleCamera: prefix + 'admin/cameras/',
    createCameras: prefix + 'admin/cameras/create',
    deleteCameras: prefix + 'admin/cameras/delete/',
    updateCameras: prefix + 'admin/cameras/update/',
    contacts: prefix + 'admin/contacts/all',
    deleteContacts: prefix + 'admin/contacts/delete/',
    staticContentUpdate: prefix + 'admin/static/update/',
    staticContent: prefix + 'admin/static/',
    subAdminList: prefix + 'admin/users/all',
    subAdminAdd: prefix + 'admin/users/create',
    subAdminView: prefix + 'admin/users/view',
    subAdminEdit: prefix + 'admin/users/update/',
    subAdminDelete: prefix + 'admin/users/delete/',
    userPermissions: prefix + 'admin/permissions',
    privilegeRoles: prefix + 'admin/roles/all',
    privilegeAdd: prefix + 'admin/roles/create',
    privilegeEdit: prefix + 'admin/roles/update/',
    privilegeDelete: prefix + 'admin/roles/delete/',
    crossingsHistory: prefix + 'admin/crossings/history',
    acknoledgement: prefix + 'admin/crossings/acknowledge/',
    cameraNotifications: prefix + 'admin/cameras/notification/all',
    cameraNotificationsAll: prefix + 'admin/cameras/notification/all/others',
    cameraNotificationAdd: prefix + 'admin/cameras/notification/create',
    cameraNotificationsUpdate: prefix + 'admin/cameras/notification/update/',
    cameraNotificationDelete: prefix + 'admin/cameras/notification/delete/',
    cameraLists: prefix + 'admin/cameras/list',
    notificationStatus: prefix + 'admin/cameras/notification/status/toggle/',
    notificationActiveAll: prefix + 'admin/cameras/notification/status/active/all',
    notificationInactiveAll: prefix + 'admin/cameras/notification/status/inactive/all',
    cameraReport: prefix + 'admin/cameras/report',
    traficReport: prefix + 'admin/trafic',
    jurisdictionList: prefix + "admin/jurisdiction",
    jurisdictionCreate: prefix + "admin/jurisdiction/create",
    jurisdictionUpdate: prefix + "admin/jurisdiction/update/",
    jurisdictionDelete: prefix + "admin/jurisdiction/delete/",
    accessDetails: prefix + "admin/access-details"
}
export const userToken = () => {
    let name = "_token=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
}
export function setSessionMessage(type, message) {
    window.sessionStorage.setItem('type', type)
    window.sessionStorage.setItem('message', message)
}

export const UserPermissions = ["add_logentry", "change_logentry", "delete_logentry", "view_logentry", "add_permission", "change_permission", "delete_permission", "view_permission", "add_group", "change_group", "delete_group", "view_group", "add_user", "change_user", "delete_user", "view_user", "add_contenttype", "change_contenttype", "delete_contenttype", "view_contenttype", "add_session", "change_session", "delete_session", "view_session", "add_crossing", "change_crossing", "delete_crossing", "view_crossing", "add_camera", "change_camera", "delete_camera", "view_camera", "add_image", "change_image", "delete_image", "view_image", "add_contact", "change_contact", "delete_contact", "view_contact", "add_info", "change_info", "delete_info", "view_info", "add_static", "change_static", "delete_static", "view_static", "change_closure", "delete_closure", "view_closure"]

export default Constants;